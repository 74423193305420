<template>
  <div class="container">
    <h2>RECUPERAR CONTRASEÑA</h2>
    <input placeholder="Correo" type="text" v-model="correo" @keyup.enter="reset" autocomplete="off" />
    <button @click="reset" :disabled="!correoValido(correo)">ENVIAR</button>
    <br />
  </div>
</template>
<script>
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { GoogleAuthProvider } from 'firebase/auth';

export default {
  name: 'Login',
  data() {
    return {
      correo: '',
    };
  },
  mounted() {
    this.setLoading(false);
  },
  methods: {
    googleAuth() {
      this.providerAuth(new GoogleAuthProvider());
    },
    async reset() {
      this.setLoading(true);
      const auth = getAuth();
      this.notification();
      try {
        await sendPasswordResetEmail(auth, this.correo);
        this.notification('Se ha enviado un correo de restablecimiento del password');
        this.$router.push('/admin/login');
      } catch (error) {
        this.notification(error, 'Reset Password Error');
      }
      this.setLoading(false);
    },
  },
};
</script>
